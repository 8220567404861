.ctamain{
    margin: 40px 0;
    font-family: Karla;
    margin-bottom: 8%;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
    
}
.inView {
    opacity: 1;
    transform: translateY(0);
    animation: slideFromLeft 1s forwards;
  }
.ctacontent span{
    font-size: 35px;
    font-weight: 400;
    letter-spacing: 3px;
}

.ctacontent h2{
margin: 20px 0;
font-size: 30px;
font-family: Karla;

letter-spacing: 1px;
text-align: center;

}

.socialMediaIcons{
  justify-content: center;
  gap: 50px;
  margin-top: 20px;
}
.app_btn{
    display: flex;
    justify-content: center;
    gap: 20px;
}
@keyframes slideFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @media (max-width: 768px) {
    .ctacontent{
      padding: 10px;
    }
    .ctacontent p{
      font-size:20px;
    }
    .ctacontent span{
      font-size: 25px;
    }
    .app_btn{
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items:  center;
  }
  }