.container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.text,
.contact {
  direction: ltr;
  font-size: 18px;
  line-height: 1.6;
}

.contact {
  margin-top: 20px;
}

.email {
  color: #0066cc;
  text-decoration: none;
}
