.logo {
  background-color: #172B6A;
  padding: 20px;
  display: flex;
  justify-content: right;
}

.logoInner {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: right;
}
.mediaPlayer {
  /* border: 2px solid #172B6A; */
  border-radius: 20px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.videoContainer {
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio (1080 / 1920 * 100) */
}
.contentError {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  font-family: Karla;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 19px;
  letter-spacing: -0.4px;
  color: #172B6A;
}

.videoWrapper,
.roundedPlayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.content {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.contentInner {
  display: flex;
  align-items: center;
  gap: 30px;
  /* flex-direction: row-reverse; */

  font-family: Karla;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  /* line-height: 19px; */
  /* letter-spacing: -0.4px; */
  color: #172B6A;
}

.contentHeading {
  font-family: Karla;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  /* line-height: 19px; */
  /* letter-spacing: -0.4px; */
  color: #172B6A;
}

@media (max-width: 900px) {
  .content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: right;
  }
  .contentHeading {
    font-size: 20px;
  }
  .contentInner {
    display: flex;
    align-items: center;
    justify-content: right;

    gap: 30px;

    font-size: 15px;
  }
}
