.faqHeading
{
    font-size: 24px;

    margin: 30px 0;
}
.faqItem{
    max-width: 65%;
    border-radius: 20px;
    margin: 10px 0 ;
    margin-left: 18%;
    margin-right: 18%;
    transition: ease-in 0.5s;
    user-select: none;
    padding: 10px 30px;
    transition: ease-in 0.3s;
    cursor: pointer;
    text-align: end;
}

.faqItem:hover{
    transform: scale(0.9);
}
.faqContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
}