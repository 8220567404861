.testmain{
        background: url(../images/testimonial-bg.png);
    background-repeat: no-repeat;
    background-size:2000%;
    background-position:center;
    padding: 20px;
    opacity: 0;
    margin-top: 50px;
    transform: translateY(50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.inView {
    opacity: 1;
    transform: translateY(0);
    animation: slideFromLeft 1s forwards;
  }
.testheading h2{
    text-align: center;
    padding: 40px;
    font-size: 50px;
    font-weight: bold;
}
.peoplename{
    display: flex;
    flex-direction: column;
    text-align: start;
    margin-left: 20px;
    margin-right: 5px;
}
.cards{
    background: white;
    border-radius: 10px;
    height: 100%;
    padding: 20px;
}
  
  @keyframes slideFromLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }