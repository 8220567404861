.navbar{
    background: inherit;
    position: fixed;
    width: 100vw;
    padding: 10px 15%;
    z-index: 3;
    transition: transform 0.3s ease-in-out;
    text-align: end;

}
.navbar.hidden{
    transform: translateY(-115%);
}
.main::after {
    content: "";
  width: 100%;
  height: 350px;

  position: absolute;
  bottom: -100px;
  left: 0;
  background:  white; 
  transform-origin: bottom left;
  transform: skewY(-3deg); 
  text-align: end;
}


.navflex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: end;
}
.btn{
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.4px;
    height: 40px;
    background: #333333;
    border-radius: 5px;
    margin-left: 15px;
    margin-right: 15px;
    border-style: none;
    padding: 11px 20px;
    text-decoration: none;
    cursor: pointer;
}
.navitem{
    padding-top: 15px;
}
.navitem a{
    color: white;
    margin: 0 5px;
    text-align: end;

}

.scrolledhalfway{
    background: white;
}
.scrolledhalfway a{
    color: black;
}
li{
    list-style: none;
}
a{
    text-decoration: none;
    color: white;
}
.mobile{
    display: none;
}
.items{
    display: flex;
    align-items: center;
}
.sidebar{
    display: flex;
    position: absolute;
    flex-direction: column;
    padding: 20px;
    border-radius: 15px;
    width: 45vw;
    background-color: "#172B6A";
}
.sidebar a{
    color: black;
    margin: 20px 0;
}

.desktop{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.hamburger{
    font-size: 25px;
}
.logo {
    width: 80%; /* Stretch to the width of the screen */
    height: 60vh; /* Set height to 10% of the viewport height */
  }
@media (max-width: 768px) {
    .items{
        display: none;
    }
    .desktop{
        display: none;
    }
    .mobile, .hamburger{
        
        display: contents;
        z-index: 999;
    }
    .navbar {
      padding: 10px 0; 
    }
    .btn{
        font-size: 12px;
    }
    .btns{
        display: flex;
        flex-direction: column;
    }
    .btns a{
        margin: 0;
        margin-bottom: 20px;
    }
    .btns button{
        margin: 0;
    }
    .navitem{
        margin: 0;
        padding: 0;
    }
    .ul{
        margin: 0;
        padding: 0;
        padding-right: 15px;
    }
  }
  @media (max-width: 281px) {
    .sidebar{
        width: 65vw;
    }
  }