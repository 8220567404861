.footermain{
    padding: 30px 0;
    overflow-x: hidden;
}   
.footeritems{
    display: flex;
    justify-content: center;
    list-style: none;
    align-items: center;
    align-self: center;
    align-content: center;
}
.footeritems a{
    color: black;
    text-decoration: none;
    align-items: center;
    align-self: center;
    align-content: center;
}
.footer{
    display: flex;
    justify-content: center;
    align-items: center;
        direction: rtl;

}
hr{
    width: 100vw;
}
@media (max-width: 998px) {
    /* .footeritems {
        display: none;
    } */
}