.App {
  text-align: center;
}

.rtl {
  direction: rtl;
}
.toggle-button {
  background-color: #333333;
  color: #fff;
  border: none;
  font-size: 15px;
  padding: 9px 20px;
  margin: 0 15px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 1000;
}
@media (max-width: 768px) {
  .toggle-button {
    margin-top: 10px; /* Mobile background color */
    font-size: 12px;
  }
}